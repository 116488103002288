.sidebar {
  background-color: #2d3748; /* Darker background */
  color: #fff; /* White text */
  width: 16rem; /* Sidebar width */
  display: flex;
  flex-direction: column;
  position: fixed; /* Ensure the sidebar is fixed */
  height: 100vh; /* Full height */
  top: 0;
  left: 0;
  overflow-y: auto; /* Add this line to enable scrolling */
}

.sidebar .sidebar-header {
  padding: 1.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
}

.sidebar nav {
  flex: 1;
  padding: 1rem;
}

.sidebar button {
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #2d3748;
  color: #fff;
  cursor: pointer;
  display: block;
}

.sidebar button:hover {
  background-color: #4a5568; /* Lighter shade for hover */
}