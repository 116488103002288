/* src/App.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
}

#orgChart, #skillMatching, #performanceDashboard {
  margin: 20px 0;
}

.flex {
  display: flex;
}

.ml-64 {
  margin-left: 16rem;
}

.p-6 {
  padding: 1.5rem;
}

.sidebar {
  background-color: #1a202c;
  color: #fff;
}

.sidebar button {
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  border: none;
  background-color: #1a202c;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.sidebar button:hover {
  background-color: #4a5568;
}

.bg-white {
  background-color: #fff;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.text-lg {
  font-size: 1.125rem;
}

.font-semibold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}