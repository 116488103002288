/* DataSection.css */

.data-section {
    padding: 1rem;
  }
  
  .upload-info {
    margin-top: 1rem;
  }
  
  .upload-info input {
    margin-bottom: 1rem; /* Adjust the spacing here as needed */
  }
  
  .no-data {
    margin-top: 1rem;
    color: red;
  }
  
  .filter-section {
    margin-top: 1rem;
  }
  
  .import-button {
    margin-bottom: 1rem;
    background-color: #4299e1; /* Tailwind blue-500 */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .import-button:hover {
    background-color: #3182ce; /* Tailwind blue-600 */
  }
  
  .filter-section select {
    width: 200px; /* Adjust the width as needed */
  }  