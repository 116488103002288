@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles can go here */
body {
  font-family: Arial, sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
}

#orgChart, #skillMatching, #performanceDashboard {
  margin: 20px 0;
}

.App {
  display: flex;
}

.App-header {
  padding: 20px;
  background-color: #282c34;
  color: white;
  text-align: center;
  width: 100%;
}

.main-content {
  margin-left: 260px; /* Adjust based on sidebar width */
  padding: 20px;
}

.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #333;
  color: white;
  padding: 20px;
}

.sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.1em;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}