/* OrgChart.css */

.org-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.orgchart {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.node {
  background-color: #4a90e2;
  color: white;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #4a90e2;
}

.node strong {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.node:hover {
  background-color: #357ab8;
  border-color: #357ab8;
}

.orgchart .lines {
  stroke: #bbb;
  stroke-width: 2px;
}

.orgchart .lines:hover {
  stroke: #888;
}
